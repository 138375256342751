import React                             from 'react';
import { Container, Row, Col }           from 'react-grid-system';
import { FaRegHeart }                    from 'react-icons/fa';
import Img                               from 'gatsby-image';
import { graphql }                       from 'gatsby';
import { GiRolledCloth, GiCandleHolder } from 'react-icons/gi';
import { BsStars }                       from 'react-icons/bs';
import { Center, useMediaQuery }         from '@chakra-ui/react';

import Link              from '@interness/web-core/src/components/elements/Link/Link';
import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { Button }        from '@interness/web-core/src/components/elements/Button/Button'
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import RandomShopItems   from '@interness/ecommerce-addon/src/components/RandomShopItems/RandomShopItems';
import BrandsDisplay     from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import SEO               from '@interness/web-core/src/components/modules/SEO/SEO';

function IndexPage({ data }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <div style={{ textAlign: 'justify' }}>
            <h1><img style={{ margin: 'auto' }} src="schriftzug.png" alt="Mit Liebe zum Detail"/></h1>
            <div>

            </div>
            <Spacer/>
            <Heading tag={'h2'} icon={<BsStars/>}>Was uns unterscheidet</Heading>
            <Container fluid>
              <Row>
                <Col md={4}>
                  <h4 style={{ textAlign: 'center' }}><b>NICHT OHNE GRUND...</b></h4>
                  <p>...steht unser Name für eine Welt voller Leidenschaft und Kreativität. Jedes Detail in unserem
                    Geschäft wurde mit Liebe und Sorgfalt ausgewählt, um eine Atmosphäre zu schaffen, die nicht nur das
                    Auge erfreut, sondern auch das Herz berührt. Wir glauben fest daran, dass die Qualität unserer Ware
                    und unser faires Preis-Leistungs-Verhältnis Ihnen nicht nur kurzfristige Zufriedenheit, sondern
                    langanhaltende Freude bringen werden.</p>
                </Col>
                <Col md={4}>
                  <h4 style={{ textAlign: 'center' }}><b>WER FARBE LIEBT...</b></h4>
                  <p>…findet bei uns ein Paradies. Wir verstehen, dass Farbe mehr ist als nur ein visuelles Element –
                    sie ist ein Ausdruck von Lebensfreude und Persönlichkeit. Deshalb bieten wir eine Palette, die jede
                    Stimmung hebt und jeden Raum zum Strahlen bringt. Unsere Geschenke? Sie sind kleine Kunstwerke,
                    verpackt mit außergewöhnlicher Liebe zum Detail und einer Prise Kreativität, die unser Engagement
                    für das Besondere widerspiegelt. Unsere Kunden wissen dieses Extra an Aufmerksamkeit zu schätzen,
                    denn jedes Geschenk von uns ist eine Botschaft des Herzens.
                  </p>
                </Col>
                <Col md={4}>
                  <h4 style={{ textAlign: 'center' }}><b>AUßERDEM…</b></h4>
                  <p>… erwecken wir Ihre Wohnträume zum Leben. Unsere Wohnraumgestaltung ist mehr als nur ein Service –
                    es ist eine persönliche Reise zu Ihrem idealen Zuhause. Direkt bei Ihnen vor Ort ergründen wir das
                    Potenzial Ihrer Räume
                    und verwandeln sie in Oasen des Wohlbefindens. Mit einer Mischung aus Fachwissen und Intuition
                    helfen wir Ihnen, die perfekte Harmonie zwischen Möbelplatzierung, Wandfarben und Gesamtgestaltung
                    zu finden. Unsere Beratung ist dabei mehr als nur ein Ratgeber – es ist ein Dialog, bei dem Ihre
                    Visionen und unsere Kreativität sich vereinen, um einzigartige Räume zu schaffen, in denen Sie sich
                    nicht nur wohnlich, sondern auch seelisch zu Hause fühlen.</p>
                </Col>
              </Row>
            </Container>
            <p>BIS BALD IN KREUZAU</p>
            <p>IHRE FAMILIE BÜLOW</p>
          </div>
        </section>
        <section>
          <Heading tag={'h2'} icon={<GiRolledCloth/>}>Aktuelle Highlights</Heading>
          <RandomShopItems/>
          <Spacer/>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/schaufenster">Mehr Produkte
              entdecken</Button>
          </Center>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiCandleHolder/>}>Neue Kollektionen entdecken</Heading>
          <BrandsDisplay/>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <Spacer/>
          <Container>
            <Row>
              <Col md={4}>
                <h4 style={{ textAlign: 'center' }}><b><FaRegHeart size={'0.8em'}/> GESCHENKGUTSCHEINE</b></h4>
                <p>Selbstverständlich bekommen Sie bei uns Geschenkgutscheine. Ihrem individuellen Budget entsprechend
                  können diese ausgestellt werden.
                  Die Gutscheine werden dem Anlass entsprechend liebevoll und aufwendig verpackt.</p>
              </Col>
              <Col md={4}>
                <h4 style={{ textAlign: 'center' }}><b><FaRegHeart size={'0.8em'}/> GESCHENKVERPACKUNGEN</b></h4>
                <p>Mit Liebe zum Detail werden Ihre ausgewählten Lieblingsstücke bei uns ganz individuell und aufwendig
                  als Geschenk verpackt.
                  Als kleines Dankeschön für Ihren Einkauf ist dieser Service für Sie kostenlos.</p>
              </Col>
              <Col md={4}>
                <h4 style={{ textAlign: 'center' }}><b><FaRegHeart size={'0.8em'}/> KOSTENFREIES PARKEN</b></h4>
                <p>Hier bei uns in Kreuzau können Sie noch kostenfrei parken!
                  Direkt vor unserem Geschäft in der Hauptstr. 60 gibt es Parkplätze, auf denen Sie mittels Parkscheibe
                  1 1/2 Std kostenfrei parken können.</p>
              </Col>
            </Row>
          </Container>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "header-images"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "header-mobile"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
